import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DateTimeRangePickerComponent } from '@next-top-tech/date-time-picker';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-cp-time-picker',
  standalone: true,
  imports: [DateTimeRangePickerComponent, ReactiveFormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <form [formGroup]="formGroup" (ngSubmit)="submit()">
      <date-time-range-picker
        fromControlName="fromDate"
        toControlName="toDate"
        fromLabel="Date From Label"
        toLabel="Date To Label"
        fromPlaceholder="Date From Placeholder"
        toPlaceholder="Date To Placeholder"
        SubmitButtonLabel="Submit"
      />
    </form>
  `,
  styles: ``,
})
export class CpTimePickerComponent implements OnInit {
  formGroup!: FormGroup;

  ngOnInit() {
    this.formInit();
  }

  formInit() {
    this.formGroup = new FormGroup({
      fromDate: new FormControl(''),
      toDate: new FormControl(''),
    });
  }

  submit() {
    console.log(
      `From Value: ${this.formGroup.get('fromDate')?.value} | To Value: ${this.formGroup.get('toDate')?.value}`,
    );
  }
}
