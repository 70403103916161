import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatChipTabComponent } from '@shared/components';

@Component({
  selector: 'app-cp-mat-chip-tab',
  standalone: true,
  imports: [MatChipTabComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <app-mat-chip-tab
      [chipsTitleList]="chipList"
      (onNewChipSelect)="setSelectedChipContent($event)"
    >
      <div class="m-5">
        @switch (selectedChip) {
          @case (0) {
            First Page Content
          }
          @case (1) {
            Second Page Content
          }
          @case (2) {
            Third Page Content
          }
          @case (3) {
            Forth Page Content
          }
        }
      </div>
    </app-mat-chip-tab>
  `,
  styles: ``,
})
export class CpMatChipTabComponent {
  chipList = ['Tab 1 Name', 'Tab 2 Name', 'Tab 3 Name', 'Tab 4 Name'];
  selectedChip: number | undefined;

  setSelectedChipContent(selectedChipIndex: number) {
    this.selectedChip = selectedChipIndex;
  }
}
