import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PromiseButtonDirective } from '@shared/directives';
import { delay, Observable, of, Subscription } from 'rxjs';
@Component({
  selector: 'app-cp-button',
  standalone: true,
  imports: [
    PromiseButtonDirective,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatProgressSpinnerModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <section>
      <div class="example-label">Trigger Loading</div>
      <div class="example-button-row">
        <button mat-button color="primary" (click)="loadData()">
          Click me to show some loadings
        </button>
      </div>
    </section>
    <mat-divider></mat-divider>

    <section>
      <div class="example-label">Text</div>
      <div class="example-button-row">
        <button mat-button color="primary" [appPromiseButton]="subscription">Enabled</button>
        <button mat-button disabled>Disabled</button>
      </div>
    </section>
    <mat-divider></mat-divider>
    <section>
      <div class="example-label">Outlined</div>
      <div class="example-button-row">
        <button mat-stroked-button color="primary" [appPromiseButton]="subscription">
          Enabled
        </button>
        <button mat-stroked-button disabled>Disabled</button>
      </div>
    </section>
    <mat-divider></mat-divider>
    <section>
      <div class="example-label">Outlined</div>
      <div class="example-button-row">
        <button
          mat-stroked-button
          color="warn"
          (click)="loadData()"
          [appPromiseButton]="subscription"
        >
          Enabled
        </button>
        <button mat-stroked-button disabled>Disabled</button>
      </div>
    </section>
    <mat-divider></mat-divider>
    <section>
      <div class="example-label">Contained</div>
      <div class="example-button-row">
        <button mat-flat-button color="warn" (click)="loadData()" [appPromiseButton]="subscription">
          Enabled
        </button>
        <button mat-flat-button disabled>Disabled</button>
      </div>
    </section>
    <mat-divider></mat-divider>
    <section>
      <div class="example-label">Link</div>
      <div class="example-button-row">
        <a mat-button color="primary">Enabled</a>
        <a mat-button disabled>Disabled</a>
      </div>
    </section>
    <mat-divider></mat-divider>
    <section>
      <div class="example-label">Text + Icon Left</div>
      <div class="example-button-row">
        <button mat-button color="primary">
          <span class="fa-regular fa-circle-plus"></span> Enabled
        </button>
        <button mat-button disabled>
          <span class="fa-regular fa-circle-plus"></span> Disabled
        </button>
      </div>
    </section>
    <mat-divider></mat-divider>
    <section>
      <div class="example-label">Outlined + Icon Left</div>
      <div class="example-button-row">
        <button mat-stroked-button color="primary">
          <span class="fa-regular fa-circle-plus"></span> Enabled
        </button>
        <button mat-stroked-button disabled>
          <span class="fa-regular fa-circle-plus"></span> Disabled
        </button>
      </div>
    </section>
    <mat-divider></mat-divider>
    <section>
      <div class="example-label">Contained + Icon Left</div>
      <div class="example-button-row">
        <button mat-flat-button color="primary">
          <span class="fa-regular fa-circle-plus"></span> Enabled
        </button>
        <button mat-flat-button disabled>
          <span class="fa-regular fa-circle-plus"></span> Disabled
        </button>
      </div>
    </section>
    <mat-divider></mat-divider>
    <section>
      <div class="example-label">Link + Icon Left</div>
      <div class="example-button-row">
        <a mat-button color="primary"><span class="fa-regular fa-circle-plus"></span> Enabled</a>
        <a mat-button disabled><span class="fa-regular fa-circle-plus"></span> Disabled</a>
      </div>
    </section>
    <mat-divider></mat-divider>
    <section>
      <div class="example-label">Text + Icon Right</div>
      <div class="example-button-row">
        <button mat-button color="primary">
          Enabled <span class="fa-regular fa-circle-plus"></span>
        </button>
        <button mat-button disabled>
          Disabled <span class="fa-regular fa-circle-plus"></span>
        </button>
      </div>
    </section>
    <mat-divider></mat-divider>
    <section>
      <div class="example-label">Outlined + Icon Right</div>
      <div class="example-button-row">
        <button mat-stroked-button color="primary">
          Enabled <span class="fa-regular fa-circle-plus"></span>
        </button>
        <button mat-stroked-button disabled>
          Disabled <span class="fa-regular fa-circle-plus"></span>
        </button>
      </div>
    </section>
    <mat-divider></mat-divider>
    <section>
      <div class="example-label">Contained + Icon Right</div>
      <div class="example-button-row">
        <button mat-flat-button color="primary">
          Enabled <span class="fa-regular fa-circle-plus"></span>
        </button>
        <button mat-flat-button disabled>
          Disabled <span class="fa-regular fa-circle-plus"></span>
        </button>
      </div>
    </section>
    <mat-divider></mat-divider>
    <section>
      <div class="example-label">Link + Icon Right</div>
      <div class="example-button-row">
        <a mat-button color="primary">Enabled <span class="fa-regular fa-circle-plus"></span></a>
        <a mat-button disabled>Disabled <span class="fa-regular fa-circle-plus"></span></a>
      </div>
    </section>
    <mat-divider></mat-divider>
    <section>
      <div class="example-label">Icon</div>
      <div class="example-button-row">
        <button mat-icon-button color="primary" class="mdc-icon-button-square">
          <span class="fa-regular fa-circle-plus"></span>
        </button>
        <button mat-icon-button disabled class="mdc-icon-button-square">
          <span class="fa-regular fa-circle-plus"></span>
        </button>
      </div>
    </section>
    <mat-divider></mat-divider>
    <section>
      <div class="example-label">Icon Primary</div>
      <div class="example-button-row">
        <button mat-icon-button color="primary" class="mdc-icon-button-square">
          <span class="fa-regular fa-circle-plus"></span>
        </button>
        <button mat-icon-button disabled class="mdc-icon-button-square">
          <span class="fa-regular fa-circle-plus"></span>
        </button>
      </div>
    </section>
  `,
  styles: `
    section {
      display: table;
    }

    .example-label {
      display: table-cell;
      font-size: 14px;
      margin-left: 8px;
      min-width: 200px;
    }

    .example-button-row {
      display: table-cell;
      max-width: 800px;
    }

    .example-button-row .mat-mdc-button-base {
      margin: 8px 8px 8px 0;
    }

    .example-flex-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .example-button-container {
      display: flex;
      justify-content: center;
      width: 300px;
    }
  `,
})
export class CpButtonComponent implements OnInit {
  dataObservable!: Observable<unknown>;
  subscription!: Subscription;
  ngOnInit() {
    this.dataObservable = of(10).pipe(delay(2000));
  }

  loadData() {
    this.subscription = this.dataObservable.subscribe();
  }
}
