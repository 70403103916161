import { ChangeDetectionStrategy, Component, inject, OnInit, signal } from '@angular/core';
import { JsonPipe } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { AsyncActionableSelectorInterface } from '@core/interfaces';
import { HttpService } from '@core/services';
import { SafeAny } from '@core/types';
import { ActionableSelectorComponent } from '@shared/components';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-cp-actionable-selector',
  standalone: true,
  imports: [
    ActionableSelectorComponent,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    MatButtonModule,
    JsonPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    All Inputs here are required :
    <form [formGroup]="testForm" (ngSubmit)="onSubmit()" class="mb-4 mt-4">
      <mat-form-field>
        <input matInput placeholder="Random Input" formControlName="someRandomForm" />
      </mat-form-field>
      <app-actionable-selector
        controlName="options1"
        placeholder="Choose Aggregate Wallet"
        [OptionGroups]="optionGroups1"
      ></app-actionable-selector>

      <div class="w-3/12">
        <app-actionable-selector
          controlName="options2"
          placeholder="Random Async Selector"
          [OptionGroups]="optionGroups2"
        ></app-actionable-selector>
      </div>
      <app-actionable-selector
        controlName="options3"
        placeholder="Async Selector Options from api call"
        [OptionGroups]="optionGroups3()"
        (onActionClick)="optionActionApiCall()"
      ></app-actionable-selector>
      <button mat-stroked-button color="primary" type="submit">Submit Form</button>
    </form>
    @if (finalForm) {
      {{ finalForm | json }}
    }
  `,
  styles: ``,
})
export class CpActionableSelectorComponent implements OnInit {
  readonly httpService = inject(HttpService);
  testForm: FormGroup = new FormGroup({});
  finalForm: SafeAny;

  optionGroups1: AsyncActionableSelectorInterface[] = [
    {
      options: [
        { value: 'USDT 2348 Value', viewValue: 'USDT 2348' },
        { value: 'Dirham 5789 Value', viewValue: 'Dirham 5789' },
      ],
    },
    {
      name: 'Add Wallet',
      hasAction: true,
      options: [
        { value: 'USDT Value', viewValue: 'USDT' },
        { value: 'Euro Value', viewValue: 'Euro' },
        { value: 'Lira Value', viewValue: 'Lira' },
      ],
    },
  ];

  optionGroups2: AsyncActionableSelectorInterface[] = [
    {
      options: [
        { value: 'bulbasaur-0', viewValue: 'Bulbasaur', disabled: true },
        { value: 'oddish-1', viewValue: 'Oddish' },
        { value: 'bellsprout-2', viewValue: 'Bellsprout' },
      ],
    },
    {
      name: 'Water',
      options: [
        { value: 'squirtle-3', viewValue: 'Squirtle' },
        { value: 'psyduck-4', viewValue: 'Psyduck' },
        { value: 'horsea-5', viewValue: 'Horsea' },
      ],
    },
    {
      name: 'Fire',
      disabled: true,
      options: [
        { value: 'charmander-6', viewValue: 'Charmander' },
        { value: 'vulpix-7', viewValue: 'Vulpix', disabled: true },
        { value: 'flareon-8', viewValue: 'Flareon' },
      ],
    },
    {
      name: 'Psychic',
      options: [
        { value: 'mew-9', viewValue: 'Mew' },
        { value: 'mewtwo-10', viewValue: 'Mewtwo' },
      ],
    },
  ];

  optionGroups3 = signal<AsyncActionableSelectorInterface[] | null>(null);

  ngOnInit() {
    this.initForm();
    this.initLazyList();
  }

  initForm() {
    this.testForm = new FormGroup({
      someRandomForm: new FormControl('', [Validators.required]),
      options1: new FormControl('', [Validators.required]),
      options2: new FormControl('', [Validators.required]),
      options3: new FormControl('', [Validators.required]),
    });
  }

  initLazyList() {
    // An Api to get one group of option data
    this.lazyList$().subscribe((data) => {
      const listWithoutAction = {
        options: data,
      };
      const firstOptionsList = [listWithoutAction];
      this.staticList(firstOptionsList);
    });
  }

  staticList(firstOptionsList: SafeAny[]) {
    // Another Api to get another group of option data
    this.httpService
      .get('/Currencies/summary')
      .pipe(
        map((item: SafeAny) =>
          item.data.items.map((subItem: SafeAny) => ({
            value: subItem.id,
            viewValue: subItem.code,
            icon: subItem.logoUrl,
          })),
        ),
      )
      .subscribe((data: SafeAny) => {
        const listWithAction = {
          name: 'Add Wallet',
          hasAction: true,
          options: data,
        };
        const finalList = [...firstOptionsList, listWithAction];
        this.optionGroups3.set(finalList);
      });
  }

  lazyList$(): Observable<SafeAny> {
    return this.httpService.get('/Partners/1/wallets').pipe(
      map((item: SafeAny) =>
        item.data.items.map((subItem: SafeAny) => ({
          value: subItem.id,
          viewValue: subItem.name,
          icon: subItem.logoUrl,
        })),
      ),
    );
  }

  optionActionApiCall() {
    this.httpService.post('/partners/1/wallet', {}).subscribe(() => {
      this.lazyList$().subscribe((data) => {
        const baseData = {
          options: data,
        };
        const updatedOptions = [...this.optionGroups3()!, baseData];
        this.optionGroups3.set(updatedOptions);
      });
    });
  }

  onSubmit() {
    this.finalForm = this.testForm.getRawValue();
  }
}
