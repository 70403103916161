import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-cp-typography',
  standalone: true,
  imports: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <h2>Typography Scale</h2>

    <h3>Headlines</h3>
    <ul>
      <li><h1 class="Headline-1">H2/Roboto/Light/96px</h1></li>
      <li><h2 class="Headline-2">H1/Roboto/Light/57px</h2></li>
      <li><h3 class="Headline-3">H3/Roboto/Regular/48px</h3></li>
      <li><h4 class="Headline-4">H4/Roboto/Regular/34px</h4></li>
      <li><h5 class="Headline-5">H5/Roboto/Regular/24px</h5></li>
      <li><h6 class="Headline-6">H6/Roboto/Medium/20px</h6></li>
    </ul>

    <h3>Subtitles</h3>
    <ul>
      <li><p class="Subtitle-1">Subtitle 1/Roboto/Regular/16px</p></li>
      <li><p class="Subtitle-2">Subtitle 2/Roboto/Medium/14px</p></li>
    </ul>

    <h3>Body Text</h3>
    <ul>
      <li><p class="Body-1">Body 1/Roboto/Regular/16px</p></li>
      <li><p class="Body-2">Body 2/Roboto/Regular/14px</p></li>
      <li><p class="Body-3">Body 3/Roboto/Regular/14px</p></li>
    </ul>

    <h3>Special Text Styles</h3>
    <ul>
      <li><p class="Button">BUTTON/Roboto/Medium/14px</p></li>
      <li><p class="Caption">Caption/Roboto/Regular/12px</p></li>
      <li><p class="Overline">OVERLINE/Roboto/Regular/10px</p></li>
    </ul>
  `,
  styles: ``,
})
export class CpTypographyComponent {}
