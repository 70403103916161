import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { ThemeService } from '@core/services';
import { MatListModule } from '@angular/material/list';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ColorModesEnum } from '@core/enums';
@Component({
  selector: 'app-dev-tool-sheet',
  standalone: true,
  imports: [MatIconModule, MatListModule, MatButtonModule, RouterModule],
  templateUrl: './dev-tool-sheet.component.html',
  styleUrl: './dev-tool-sheet.component.scss',
})
export class DevToolSheetComponent {
  themeService = inject(ThemeService);
  ColorModesEnum = ColorModesEnum;
  constructor(private _bottomSheetRef: MatBottomSheetRef<DevToolSheetComponent>) {}
  closeDevTool() {
    this._bottomSheetRef.dismiss();
  }
}
