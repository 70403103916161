import { JsonPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { AutocompleteConfig } from '@core/components';
import { ROUTES } from '@core/constants';
import { ApiResponse, Bank, Customer, ExchangeRate, PaginatedResponse } from '@core/models';
import { HttpService } from '@core/services';
import { CommonValidators } from '@core/validators';
import { AutocompleteComponent } from '@shared/components';
import { map } from 'rxjs';

@Component({
  selector: 'app-searchable-select',
  standalone: true,
  imports: [AutocompleteComponent, FormsModule, ReactiveFormsModule, JsonPipe, MatButton],
  template: `
    <form [formGroup]="form">
      <!-- Select User(Optional) -->
      <app-autocomplete
        controlName="userNotRequired"
        label="Assignee"
        [config]="userConfigNotRequired"
      >
        <ng-template let-user> {{ user.email }} - {{ user.firstName ?? 'unknown' }}</ng-template>
      </app-autocomplete>
      <app-autocomplete controlName="user" label="Select User" [config]="userConfig" />

      <!-- Select Exchange Rate -->
      <app-autocomplete
        controlName="exchangeRate"
        label="Select Exchange Rate"
        [config]="exchangeRateConfig"
      >
        <ng-template let-rate> {{ rate.marketCurrency.code }}</ng-template>
      </app-autocomplete>

      <!-- Select Bank -->
      <app-autocomplete controlName="bankId" label="Select Bank" [config]="bankConfig">
        <ng-template let-bank>
          <span class="app-select-option"
            ><img [src]="bank.logoUrl" alt="Bank Logo" />{{ bank.name }}</span
          >
        </ng-template>
      </app-autocomplete>
      <button mat-button color="primary" (click)="form.reset()">Reset</button>
    </form>
    <pre>{{ form.value | json }}</pre>
  `,
  styles: [``],
})
export class CpAutocompleteComponent {
  ROUTES = ROUTES;
  _httpService = inject(HttpService);

  form = new FormGroup({
    userNotRequired: new FormControl(null),
    user: new FormControl(null, [CommonValidators.required]),
    exchangeRate: new FormControl(null, [CommonValidators.required]),
    bankId: new FormControl(null, [CommonValidators.required]),
  });
  // Configuration for fetching and displaying users
  userConfigNotRequired: AutocompleteConfig<Customer> = {
    fetchItems: () =>
      this._httpService
        .get<PaginatedResponse<Customer>>(ROUTES.users.list)
        .pipe(map((res) => res.data.items)),
    displayItem: (item) => (item ? item.email : ''),
  };
  // Configuration for fetching and displaying users
  userConfig: AutocompleteConfig<Customer> = {
    strictValidation: true,
    fetchItems: () =>
      this._httpService
        .get<PaginatedResponse<Customer>>(ROUTES.users.list)
        .pipe(map((res) => res.data.items)),
    displayItem: (item) => (item ? item.email : ''),
  };

  // Configuration for fetching and displaying exchange rates
  exchangeRateConfig: AutocompleteConfig<ExchangeRate> = {
    fetchItems: () =>
      this._httpService
        .get<ApiResponse<{ exchangeRateItemResponses: ExchangeRate[] }>>(ROUTES.exchangeRate.list)
        .pipe(map((res) => res.data.exchangeRateItemResponses)),
    displayItem: (item) => (item && item.marketCurrency ? `${item.marketCurrency.code}` : ''),
  };

  // Configuration for fetching and displaying banks
  bankConfig: AutocompleteConfig<Bank> = {
    fetchItems: () =>
      this._httpService
        .get<PaginatedResponse<Bank>>(ROUTES.configuration.banks.list)
        .pipe(map((res) => res.data.items)),
    displayItem: (item) => (item ? item.name : ''),
  };
}
