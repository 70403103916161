import { Component } from '@angular/core';
import { RetryComponentComponent } from '@shared/components';

@Component({
  selector: 'app-cp-retry-component',
  standalone: true,
  imports: [RetryComponentComponent],
  template: `
    @if (isRefreshCalled.first) {
      <p>1st Refresh Action Caught From Parent Component!</p>
    }
    <app-retry-component
      [error]="{ name: 'test', message: 'test' }"
      (onRetry)="mockFetchingData('first')"
    ></app-retry-component>
    <hr />
    @if (isRefreshCalled.second) {
      <p>2nd Refresh Action Caught From Parent Component!</p>
    }
    <app-retry-component
      [error]="{ name: 'test', message: 'test' }"
      [showIcon]="false"
      [showTitle]="false"
      [showDescription]="false"
      (onRetry)="mockFetchingData('second')"
    ></app-retry-component>
    <hr />
    @if (isRefreshCalled.third) {
      <p>3rd Refresh Action Caught From Parent Component!</p>
    }
    <app-retry-component
      [error]="{ name: 'test', message: 'test' }"
      [showTitle]="false"
      [showDescription]="false"
      (onRetry)="mockFetchingData('third')"
    ></app-retry-component>
    <hr />
    @if (isRefreshCalled.forth) {
      <p>4th Refresh Action Caught From Parent Component!</p>
    }
    <app-retry-component
      [error]="{ name: 'test', message: 'test' }"
      [title]="'Hello there. I am the Title'"
      [description]="'Hello again. I am the description.'"
      (onRetry)="mockFetchingData('forth')"
    ></app-retry-component>
  `,
  styles: `
    hr {
      border: none;
      border-top: 1px solid #ccc;
    }
  `,
})
export class CpRetryComponent {
  isRefreshCalled = { first: false, second: false, third: false, forth: false };
  mockFetchingData(id: 'first' | 'second' | 'third' | 'forth') {
    this.isRefreshCalled[id] = true;
    console.log(`${id} Retry component Button Worked in parent.`);
  }
}
